
// Variables
$light: #fff;
$white: #fff;
$primary-color: #A5CD39;
$primary-bg: #353b43;
$border-color:rgba(236, 239, 241, 0.07);
$black: #000;
$section-bg : #292F36;
$primary-font: 'Quattrocento Sans', sans-serif;
$secondary-font: 'Anaheim', sans-serif;
$icon-font: 'themefisher-font';